import './CompetitionLeft.scss';
import { useCompetitionStats } from 'context/SyntheticsStateContext/hooks/competitionHooks';

export function CompetitionLeft() {
  const { timeRemaining, totalCompPrize, AccountRoi, account } = useCompetitionStats();

  if (!account) {
    return (
      <div className="competition-left-container">
        <div>Please connect your wallet to view the competition data</div>
      </div>
    );
  } else if (!totalCompPrize) {
    return (
      <div className="competition-left-container">
        <div className="info-section">
          <div className="competition-row">Loading competition data...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="competition-left-container">
      <div className="info-section competition-height-match">
        <div className="competition-row">Total Current Prize: USD ${totalCompPrize.toFixed(2)}</div>
        <div className="competition-row">{timeRemaining}</div>
        <div className="competition-row">ROI: {AccountRoi ? AccountRoi : "0"}% (After Fees)</div>
      </div>
    </div>
  );
}
